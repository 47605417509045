import axios from "axios";
import {AXIOS_CONFIGURATION} from "../Core/Constants";

export function getGoodPracticesByName(goodPracticeName: string) {
    return axios.get(AXIOS_CONFIGURATION.url + '/good_practices/by_name/' + goodPracticeName, AXIOS_CONFIGURATION.config)
}

export function getGoodPracticesByNameToMd(goodPracticeName: string) {
    return axios.get(AXIOS_CONFIGURATION.url + '/good_practices/by_name_to_md/' + goodPracticeName, AXIOS_CONFIGURATION.config)
}

export function getAllCourses() {
    return axios.get(AXIOS_CONFIGURATION.url + '/good_practices/', AXIOS_CONFIGURATION.config)
}