import {useEffect, useRef, useState} from 'react';
import {getGoodPracticesByName} from "../../Services/GoodPracticesService";
import * as React from "react";
import Reveal from "reveal.js";
import "reveal.js/dist/reveal.css";
import "reveal.js/dist/theme/black.css";
import RevealMarkdown from 'reveal.js/plugin/markdown/markdown';
import RevealHighlight from 'reveal.js/plugin/highlight/highlight';
import RevealNotes from "reveal.js/plugin/notes/notes";
import {useLocation} from "react-router-dom";
import './presentation.css';


export function Presentation() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const name = params.get('name') || '';
    const isPrintPdf = params.has('print-pdf');
    const [contentPayload, setContentPayload] = useState<string>("");
    const deckDivRef = useRef<HTMLDivElement>(null);
    const deckRef = useRef<Reveal.Api | null>(null);

    const escapeHTML = (text: string) => {
        return text.replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    };

    useEffect(() => {
        if (!contentPayload) return;
        if (deckRef.current) return;

        deckRef.current = new Reveal(deckDivRef.current!, {
            transition: "slide",
            // other config options
        });

        deckRef.current.initialize(
            {
                // center: false,
                hash: true,
                plugins: [RevealMarkdown, RevealNotes, RevealHighlight],
                markdown: {
                    smartypants: true,
                },
                showNotes: isPrintPdf ? "separate-page" : false,
            },
        ).then(() => {
            // good place for event handlers and plugin setups
        });

        return () => {
            try {
                if (deckRef?.current?.isReady()) {
                    deckRef.current.destroy();
                    deckRef.current = null;
                }
            } catch (e) {
                console.warn("Reveal.js destroy call failed.");
            }
        };
    }, [contentPayload]);

    useEffect(() => {
        getGoodPracticesByName(name).then((response) => {
            setContentPayload(
                response.data.replace(
                    /<code\b([^>]*)>(.*?)<\/code>/gs,
                    (match: string, attributes: string, codeContent: string) => `<code${attributes}>${escapeHTML(codeContent)}</code>`
                )
            );
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    return (
        <div className={isPrintPdf ? "w-full h-full" : "grow flex flex-row"}>
            <div className="reveal" ref={deckDivRef}>
                <div className="slides" dangerouslySetInnerHTML={{__html: contentPayload}}>
                    {/*<section*/}
                    {/*    data-markdown=""*/}
                    {/*    data-separator="^\n\n\n"*/}
                    {/*    data-separator-vertical="^\n\n"*/}
                    {/*    data-separator-notes="^Notes :"*/}
                    {/*    // data-charset="iso-8859-15"*/}
                    {/*>*/}
                    {/*    <script type="text/template">*/}
                    {/*        {contentPayload}*/}
                    {/*    </script>*/}
                    {/*</section>*/}
                </div>
            </div>
        </div>
    );
}