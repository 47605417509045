import {useEffect, useState} from 'react';
import './WebStatic.css';
import {getCourseByName, getAllCourses} from "../Services/CourseService";
import {getExerciceByName, getAllExercices} from "../Services/ExerciceService";
import {getExamenByName, getAllExamen} from "../Services/ExamenService";
import parse from 'html-react-parser';
import hljs from 'highlight.js';
import 'highlight.js/styles/stackoverflow-dark.min.css';
import {Button, Select, Sidebar} from "flowbite-react";
// import DispatcherFormDialog from "../Components/FormDialog/DispatcherFormDialog";
// import {ViewType, ActionType} from "../Core/Constants";
// import {Logger} from '../Core/Logging';
// import StatusPopup from "../Components/StatusPopup/StatusPopup";
// const logger = Logger('Applications component')
// import 'highlight.js/styles/atom-one-dark.min.css';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {AxiosResponse} from "axios";
import * as React from "react";



export type Heading = {
    id: string;
    text: string | null;
    level: string;
}

export type HeadingCurrentLevel = {
    h2: number;
    h3: number;
    h4: number;
    h5: number;
    h6: number;
}

export function WebStatic() {
    const [availableContent, setAvailableContent] = useState<string[]>([]);
    const [selectedContent, setSelectedContent] = useState<string>("");
    const [contentPayload, setContentPayload] = useState<string>("");
    const [content, setContent] = useState<string>("");
    const [headings, setHeadings] = useState<Heading[]>([]);
    const location = useLocation();
    const subLinkName = location.state?.subLinkName;

    let getAllContents: () => Promise<AxiosResponse<any, any>>
    let getAllContentByName: (name: string) => Promise<AxiosResponse<any, any>>

    window.scroll({
        top: 0
    });

    switch (subLinkName) {
        case 'Exercices':
            getAllContents = getAllExercices
            getAllContentByName = getExerciceByName
            break;
        case 'Examen':
            getAllContents = getAllExamen
            getAllContentByName = getExamenByName
            break;
        default:
            getAllContents = getAllCourses
            getAllContentByName = getCourseByName
    }

    const linkDispatcher = (linkName: string) => {
        switch (linkName) {
            case 'Cours':
                return <div id="content" className="m-3 mt-5 pb-3 bg-slate-100 text-slate-900 rounded-lg w-full">
                    {parse(content, {
                        // replace(domNode) {
                        //     if (domNode.type === 'tag' && domNode.name === 'table') {
                        //         domNode.attribs = {
                        //             ...domNode.attribs,
                        //             class: `${domNode.attribs?.class || ''} table-fixed text-wrap break-words`.trim(),
                        //         };
                        //     }
                        //     return domNode; // Retourne le nœud modifié
                        // }
                    })}
                </div>
            case 'Exercices':
                return <div id="content" className="m-3 mt-5 pb-3 bg-slate-100 text-slate-900 rounded-lg w-full">
                    {
                        parse(content, {
                            // replace(domNode) {
                            //     console.dir(domNode, {depth: null});
                            // },
                        })
                    }
                </div>
            case 'Examen':
                return <div id="content" className="m-3 mt-5 pb-3 bg-slate-100 text-slate-900 rounded-lg w-full">
                    {parse(content)}
                </div>
        }
    }

    useEffect(() => {
        getAllContents().then((response) => {
            setAvailableContent(response.data);
            if (response.data.length) {
                console.log(response.data)
                setSelectedContent(response.data[0]);
            } else {
                setSelectedContent("");
            }
        }).catch((error) => {
            console.log(error);
        })
    }, [subLinkName]);

    useEffect(() => {
        if (!selectedContent) {
            return
        }
        getAllContentByName(selectedContent).then((response) => {
            setContentPayload(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }, [selectedContent]);

    useEffect(() => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(contentPayload, 'text/html');

        let headingCurrentLevel: HeadingCurrentLevel = {
            h2: 0,
            h3: 0,
            h4: 0,
            h5: 0,
            h6: 0,
        }

        function currentHeadingLevel(heading: string): string {
            switch (heading) {
                case "H2":
                    headingCurrentLevel.h3 = 0;
                    headingCurrentLevel.h4 = 0;
                    headingCurrentLevel.h5 = 0;
                    headingCurrentLevel.h6 = 0;
                    return `${(headingCurrentLevel.h2 += 1).toString()}.`
                case "H3":
                    headingCurrentLevel.h4 = 0;
                    headingCurrentLevel.h5 = 0;
                    headingCurrentLevel.h6 = 0;
                    return `${(headingCurrentLevel.h2).toString()}.${(headingCurrentLevel.h3 += 1).toString()}.`;
                case "H4":
                    headingCurrentLevel.h5 = 0;
                    headingCurrentLevel.h6 = 0;
                    return `${(headingCurrentLevel.h2).toString()}.${(headingCurrentLevel.h3).toString()}.${(headingCurrentLevel.h4 += 1).toString()}.`;
                case "H5":
                    headingCurrentLevel.h6 = 0;
                    return `${(headingCurrentLevel.h2).toString()}.${(headingCurrentLevel.h3).toString()}.${(headingCurrentLevel.h4).toString()}.${(headingCurrentLevel.h5 += 1).toString()}.`;
                case "H6":
                    return `${(headingCurrentLevel.h2).toString()}.${(headingCurrentLevel.h3).toString()}.${(headingCurrentLevel.h4).toString()}.${(headingCurrentLevel.h5).toString()}.${(headingCurrentLevel.h6 += 1).toString()}.`;
                default:
                    return ""
            }
        }

        const extractedHeadings: Heading[] = Array.from(doc.querySelectorAll('h1, h2, h3, h4, h5, h6')).map((heading) => {
            const currentLevel: string = currentHeadingLevel(heading.tagName)
            heading.id = currentLevel || (heading.textContent || '');
            heading.textContent = `${currentLevel.toString()} ${heading.textContent}`;
            return {
                id: heading.id,
                text: heading.textContent,
                level: heading.tagName
            };
        });
        setHeadings(extractedHeadings);
        setContent(doc.body.innerHTML);
    }, [contentPayload]);

    useEffect(() => {
        hljs.highlightAll();
    }, [content]);

    return (
        <div className="grow flex flex-row">
            <div className="sticky top-20 ml-3 h-fit">
                <Sidebar id="navigation" className="overflow-y-auto h-[calc(100vh-100px)]">
                    <div className="max-w-md">
                        <Select id="selectedCourse"
                                value={selectedContent}
                                onChange={(event) => {
                                    setSelectedContent(event.target.value)
                                }} required
                        >
                            {availableContent.map((course: string, index: number) => {
                                const match = course.match(/(?:\d+\.\s*)?([^.]+)/);
                                const documentName = match ? match[1] : course;
                                return (<option key={index} value={course}>
                                    {/*{*/}
                                    {/*    course.charAt(0).toUpperCase() + course.slice(1).split('.')[0].replace("_", " ")*/}
                                    {/*}*/}
                                    {documentName.charAt(0).toUpperCase() + documentName.slice(1).replace("_", " ")}
                                </option>);
                            })}
                        </Select>
                    </div>
                    <Sidebar.Items>
                        <Sidebar.ItemGroup>
                            {headings.map((heading, index) => (
                                <Sidebar.Item
                                    key={index}
                                    onClick={() => {
                                        console.log("test")
                                        const element = document.getElementById(heading.id);
                                        element?.scrollIntoView({
                                            behavior: 'smooth'
                                        });
                                    }}
                                    className="cursor-pointer"
                                >
                                    <span className="text-wrap">{heading.text}</span>
                                </Sidebar.Item>
                            ))}
                        </Sidebar.ItemGroup>
                    </Sidebar.Items>
                </Sidebar>
            </div>
            {linkDispatcher(subLinkName)}
        </div>
    );
}