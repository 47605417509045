import * as React from 'react';
import {Dropdown, Navbar, Button} from "flowbite-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {faCircle, faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {faUser} from '@fortawesome/free-regular-svg-icons';
import {useLocation} from "react-router-dom";
// import {Home} from "../Views/Home";
import {useNavigate} from "react-router-dom";


export type View = {
    name: string,
    link: string,
    component: React.ReactNode,
    icon?: React.ReactNode,
    subLinks?: subLink[]
}

type subLink = {
    name: string,
    link: string
}

type NavBarPros = {
    views: View[]
}

export function NavBar({views}: NavBarPros) {
    const location = useLocation();
    const navigate = useNavigate();



    return (
        <Navbar fluid rounded className="sticky top-0 z-50">
            <Navbar.Brand href="/">
                {/*<img src="/favicon.svg" className="mr-3 h-6 sm:h-9" alt="Testing API Logo"/>*/}
                <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
                    R.A.A.K.
                </span>
            </Navbar.Brand>
            <div className="flex md:order-2">
                <Dropdown
                    arrowIcon={false}
                    inline
                    label={
                        <FontAwesomeIcon icon={faUser} size="2xl" inverse mask={faCircle}/>
                    }
                >
                    <Dropdown.Header>
                        <span className="block text-sm">User</span>
                        <span className="block truncate text-sm font-medium">user@email.com</span>
                    </Dropdown.Header>
                    <Dropdown.Item>Settings</Dropdown.Item>
                    <Dropdown.Divider/>
                    <Dropdown.Item>Sign out</Dropdown.Item>
                </Dropdown>
                <Navbar.Toggle/>
            </div>
            <Navbar.Collapse>
                {views.map((view: View, viewIndex) => (
                    view.subLinks ? (
                        <Dropdown
                            key={viewIndex}
                            inline={true}
                            renderTrigger={() => {
                                return <Button
                                    key={viewIndex}
                                    color={location.pathname === view.link ? "light" : "dark"}
                                >
                                    {view.icon}
                                    {view.name}
                                    {/*<FontAwesomeIcon icon={faChevronDown} className="ml-2 h-4 w-4"/>*/}
                                    <FontAwesomeIcon icon={faAngleDown} className="mt-0.5 ml-2 h-4 w-4"/>
                                </Button>
                            }}
                        >
                            {view.subLinks.map((subLink, subLinkIndex) => (
                                <Dropdown.Item
                                    key={subLinkIndex}
                                    // href={subLink.link}
                                    onClick={() => navigate(subLink.link, {state: {subLinkName: subLink.name}})}
                                >
                                    {subLink.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown>
                    ) : (
                        <Button key={viewIndex}
                                href={view.link}
                                color={location.pathname === view.link ? "light" : "dark"}
                                className="[&:is(a)]:hover:bg-gray-700 [&:is(a)]:hover:border-gray-700"
                        >
                            {view.icon}
                            {view.name}
                        </Button>
                    )
                ))}
            </Navbar.Collapse>
        </Navbar>
    )
        ;

}