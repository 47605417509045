import React from 'react';
import './WebStatic.css';
import {Card, Button} from "flowbite-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faPrint} from "@fortawesome/free-solid-svg-icons";


export function ExamenGenerator() {

    const questions = [
        {
            number: "1",
            statement: "Enoncer",
            proposals: "Proposition",
        },
        {
            number: "2",
            statement: "Enoncer",
            proposals: "Proposition",
        },
        {
            number: "3",
            statement: "Enoncer",
            proposals: "Proposition",
        },
    ]

    return (
        <div className="grow">
            <div id="content" className="m-3 mt-5 pb-3 bg-slate-100 text-slate-900 rounded-lg">
                <div id="qcm" className="grid grid-cols-3">
                    <div>
                        <p className="w-3/4 border-b-4 mx-auto">Nom:</p>
                        <p className="w-3/4 border-b-4 mx-auto">Prénom:</p>
                    </div>
                    <div>
                        <h1>1<sup>re</sup> année BES webdesigner UI/UX</h1>
                        <h1>Création d'application Web statiques</h1>
                        <h1>Test Q.C.M.</h1>
                    </div>
                    <div>
                        <div className="w-3/4 grid grid-cols-2 mx-auto">
                            <p className="border-b-4 text-left">Note:</p>
                            <p className="border-b-4 text-right">/ 10</p>
                        </div>
                        <div className="w-3/4 mx-auto">
                            <p className="border-b-4 text-left">Année:</p>
                        </div>
                    </div>

                </div>

                <div className="grid grid-cols-2">
                    <div className="border-2 w-80 mx-auto p-2">
                        <p>
                            Règlement :
                        </p>
                        <ul>
                            <li>Aucun document autorisé.</li>
                            <li>Aucun smartphone.</li>
                            <li>Aucun ordinateur.</li>
                            <li>Aucune discussion avec ces voisins.</li>
                        </ul>
                    </div>

                    <div className="border-2 w-80 mx-auto p-2">
                        <p>
                            Règles pour la notation :
                        </p>
                        <ul>
                            <li>On part de 0 point.</li>
                            <li>Une réponse correcte = +1 point</li>
                            <li>Une réponse erronée = +0 point</li>
                        </ul>
                    </div>
                </div>

                <div id="content">
                    {questions.map((question, index) =>
                        <>
                            <div className="mt-3"><h2>Question N°{question.number}</h2></div>
                            <div className="mt-2">{question.statement}</div>
                            <div className="mt-2">{question.proposals}</div>
                        </>
                    )}

                </div>
            </div>
        </div>
    );
}