import {AxiosRequestConfig} from "axios";

// const username = "username"
// const password = "password"
// const token = "bnRlbmQiLCJpbnN0aXR1dGlvbl9pZCI6MSwiYXBwbGljYXRpb25faWQiOjF9.xak8ztiQj3bKOq7HfCOzTPOsPUdXtnTGaHj4cb-7UeA"

// function encodeBasicAuth(username, password) {
//   const encoder = new TextEncoder();
//   const credentials = `${username}:${password}`;
//   const encoded = encoder.encode(credentials);
//   return btoa(String.fromCharCode(...new Uint8Array(encoded)));
// }

function loadConfigSync(): any {
    const request = new XMLHttpRequest();
    request.open('GET', `${process.env.PUBLIC_URL}/configuration.json`, false); // Synchronous request
    request.send(null);

    if (request.status === 200) {
        return JSON.parse(request.responseText);
    } else {
        throw new Error(`Failed to load configuration: ${request.status}`);
    }
}

const config = loadConfigSync();

type AxiosConfiguration = {
    url: string;
    config: AxiosRequestConfig;
};

// type Immutable<T> = {
//   readonly [K in keyof T]: Immutable<T[K]>;
// };

export const AXIOS_CONFIGURATION: AxiosConfiguration = Object.freeze({
    url: `${config.API.PROTOCOL}://${config.API.SERVER}:${config.API.PORT}`,
    config: {
        withCredentials: false,
        // auth: {
        //     username: '',
        //     password: ''
        // },
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "*",
            // "Authorization": axios.defaults.headers.common['Authorization'] + ", App testouille"
            // "Authorization": [
            //     `Basic ${encodeBasicAuth(username, password)}`,
            //     `App ${token}`
            // ]
        }
    },
})
