import React from 'react';
import './App.css';
import {NavBar, View} from "./Components/NavBar";
import {Routes, Route, Navigate} from "react-router-dom";
import {Home} from "./Views/Home";
// import {AsciinemaProcessing} from "./Views/AsciinemaProcessing";
// import {MarkdownImporter} from "./Views/MarkdownImporter";
import {WebStatic} from "./Views/WebStatic";
import {GoodPractices} from "./Views/GoodPractices";
import {
    // faListCheck,
    faChartLine,
    faBook,
    faFileSignature
    // faSquareShareNodes
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Presentation} from "./Components/Presentation/Presentation";
import {ExamenGenerator} from "./Views/ExamenGenerator";

// import {CollaborationWebsocketProvider} from "./Services/CollaborationWebsocket";

function App() {

    const revealJsPresentations: View[] = [
        {
            name: 'Bdl',
            link: '/good_practices/presentation',
            component: <Presentation/>,
        }
    ];

    const views: View[] = [
        // {
        //     name: 'Home',
        //     link: '/',
        //     component: <Home/>,
        //     icon: <FontAwesomeIcon icon={faChartLine} className="mr-2 h-5 w-5"/>,
        // },
        // {
        //     name: 'AsciinemaProcessing',
        //     link: '/asciinema_processing',
        //     component: <CollaborationWebsocketProvider><AsciinemaProcessing/></CollaborationWebsocketProvider>,
        //     icon: <FontAwesomeIcon icon={faSquareShareNodes} className="h-3.5 px-2"/>
        // },
        // {
        //     name: 'MarkdownImporter',
        //     link: '/markdown_importer',
        //     component: <MarkdownImporter/>,
        //     icon: <FontAwesomeIcon icon={faListCheck} className="h-3.5 px-2"/>
        // },
        {
            name: 'Web Static',
            link: '/web_static',
            component: <WebStatic/>,
            icon: <FontAwesomeIcon icon={faBook} className="mr-2 h-5 w-5"/>,
            subLinks: [
                {name: 'Cours', link: '/web_static'},
                {name: 'Exercices', link: '/web_static'},
                {name: 'Examen', link: '/web_static'}
            ]
        },
        {
            name: 'Bonnes pratiques',
            link: '/good_practices',
            component: <GoodPractices/>,
            icon: <FontAwesomeIcon icon={faBook} className="mr-2 h-5 w-5"/>,
        },
        {
            name: 'Générateur d\'examen',
            link: '/generateur_examen',
            component: <ExamenGenerator/>,
            icon: <FontAwesomeIcon icon={faFileSignature} className="mr-2 h-5 w-5"/>,
        },
    ];

    return (
        <div
            className="App font-sans antialiased bg-slate-200 dark:bg-gray-900 min-h-screen text-gray-900 dark:text-slate-200 flex flex-col"
        >
            <NavBar views={views}/>
            <div className="flex grow">
                <Routes>
                    {views.map((view) => <Route
                            key={view.name}
                            path={view.link}
                            element={view.component}
                        />
                    )}
                    {revealJsPresentations.map((view) => <Route
                            key={view.name}
                            path={view.link}
                            element={view.component}
                        />
                    )}
                    <Route path="*" element={<Navigate to="/"/>}/>
                </Routes>
            </div>
        </div>
    );
}

export default App;
